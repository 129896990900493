import React from 'react';
import {Row,Col,Container} from 'react-bootstrap';

function Footer() {
  return (
    <>
    <footer style={{ padding: '0', margin: '0' }}>
      <Container>
        <Row className="align-items-center">
          <Col md={6} className="text-md-left text-center">
            <p style={{ margin: '0' }}>
              Contact Us &nbsp;&nbsp;<a href="mailto:support@techconnectfoundation.com">support@techconnectfoundation.com</a>
            </p>
          </Col>
          <Col md={6} className="text-md-right text-center">
            <p style={{ margin: '0' }}>
              &copy; 2024 techconnectfoundation.com. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
    </>
  );
}

export default Footer;
